import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import MapComponent from "./Map.js";

const App = () =>
    <Router>
        <Routes>
            <Route index    element=<MapComponent/>/>
            <Route path="*" element=<div>Not found</div>/>
        </Routes>
    </Router>

export default App;
