import React from "react";
import Map from "ol/Map.js";
import View from "ol/View.js";
import FullScreen from "ol/control/FullScreen";
import ZoomSlider from "ol/control/ZoomSlider";
import MousePosition from "ol/control/MousePosition";
import {format} from "ol/coordinate";
import {MapboxVectorLayer} from "ol-mapbox-style";
import {useGeographic} from "ol/proj.js";

import "ol/ol.css";
import "./App.css";


class MapComponent extends React.Component {

    render = () => {
        return <div id="map"></div>;
    }

    componentDidMount = () => {
        useGeographic(); // Use geographic coordinates (WGS-84 datum) in API methods

        document.title = "Map";

        const styleUrl = "/map/styles/" + (new URLSearchParams(window.location.search).get("style") || "bright") + ".json";

        new Map({
            target: "map",
            controls : [
                new FullScreen(),
                new ZoomSlider(),
                new MousePosition({
                    placeholder : "",
                    projection  : "EPSG:4326",
                    coordinateFormat : function(coordinate){
                        return format(coordinate, "{y} {x}", 4);
                    },
                }),
            ],
            layers: [
                new MapboxVectorLayer({
                    styleUrl: styleUrl,
                }),
            ],
            view: new View({
                //center: [13.42,52.52], // Berlin
                //center: [30.52, 50.45], // Kyiv
                center: [32,49], // Ukraine
                //extent: [22.0,40.8,42.0,53.0],
                zoom: 6,
            }),

        });
    }
}


export default MapComponent;

